/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import 'sanitize.css';
import './src/styles/global.scss';
import { configure } from 'mobx';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@rmwc/button/styles';
import '@rmwc/drawer/styles';
import '@rmwc/list/styles';
import '@rmwc/menu/styles';
import '@fortawesome/fontawesome-svg-core/styles.css';

configure({ enforceActions: 'never' });
config.autoAddCss = false;
